body {
  line-height: 1;
  font-family: Avenir;
}

body > div {
  margin: auto;
}

p {
  text-align: justify;
}

li {
  text-align: justify;
}

h1 {
  font-size: 25px;
  font-family: "Homemade Apple", cursive;
  color: #523a28;
}

.page h2 {
  font-size: 20px;
  font-family: "Homemade Apple", cursive;
  color: #523a28;
  padding-left: 390px;
  line-height: 2em;
}

a:link {
  color: #523a28;
}

a:visited {
  color: #523a28;
}

a:hover {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

#stinkhorn {
  padding-left: 300px;
}

#halfsize {
  width: 250px;
  height: auto;
  padding-left: 300px;
}

.page p {
  font-size: 20px;
  font-family: "Homemade Apple", cursive;
  color: #523a28;
  line-height: 2em;
}

#blurb {
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: center;
  line-height: 2em;
}

h2 {
  font-size: 21px;
  font-family: Georgia;
  color: #523a28;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

nav ul {
  list-style: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

.panel {
  max-width: 1000px;
}

.flexCenter {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexCenterColumn {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 600px;
  clear: both;
  position: relative;
}

.container input {
  width: 95%;
  clear: both;
}

.transparent {
  background-color: transparent;
}

.fields {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #523a28;
}

.sameline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

textarea {
  width: 95%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f2f3f4;
  color: #333;
  resize: none;
}

.mushroomdisplay {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border: 1px solid;
  padding: 10px;
  box-shadow: 2.5px 2.5px #523a28;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
}

.terms {
  font-size: small;
  width: 95%;
  padding-left: 10px;
  padding-right: 50px;
  text-align: justify;
}

.pencil {
  position: relative;
  top: -440px;
  left: 450px;
}

.magnify {
  position: relative;
  top: -2100px;
  left: 1150px;
  width: 50%;
  zoom: 50%;
}

.knife {
  position: relative;
  top: -1650px;
  left: 400px;
}

.purple {
  position: relative;
  top: -1400px;
  left: 520px;
}

.unknown {
  position: relative;
  top: -1500px;
  left: 800px;
}

.elephant {
  position: relative;
  top: -400px;
  left: 560px;
}

.turkeyside {
  position: relative;
  top: -360px;
  left: 350px;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f2f3f4;
  color: #333;
  resize: none;
}

select {
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f2f3f4;
  color: #333;
  resize: none;
}

form {
  background-color: #f5f7f1;
}

form p {
  width: 95%;
  padding-left: 10px;
  text-align: justify;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

body,
html {
  background-color: #f2f3f4;
  line-height: 1.3;
}

#mainpanel {
  display: block;
  width: 100%;
  background: none;
}

#members {
  display: block;
  border-radius: 50%;
  width: 315px;
  height: 315px;
  margin: 40px auto;
  background: none;
}

#didyouknow {
  display: block;
  width: 315px;
  height: 315px;
  margin: 40px auto;
  background: none;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  background: none;
  color: gray;
  font-family: Georgia, serif;
  font-style: italic;
  line-height: 1.4 !important;
  position: relative;
  text-shadow: 0 1px white;
  z-index: 600;
}

cite {
  color: gray;
  display: block;
  font-size: 1em;
  padding-left: 50px;
  margin-bottom: 2em;
}

cite span {
  color: #5e5e5e;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 1px #81b0ac;
}

.wrappedimage img {
  padding: 0px 10px 0px 0px;
}

blockquote p {
  display: inline;
}

header {
  background: #333;
  padding: 20px 30px;
  max-width: 940px;
  margin: auto;
}

ul {
  padding-left: 50px;
}

nav {
  display: inline-block;
}

nav ul li a {
  background: #eee;
  padding: 2px 10px;
  font-size: 20px;
  text-decoration: none;
  font-weight: bold;
  color: #523a28;
  border-radius: 10px;
}

nav ul li a:hover {
  background: #fff;
}

nav ul li {
  display: inline-block;
  margin: 10px;
}

nav ul {
  list-style: none;
}

#pageContent {
  max-width: 1000px;
  margin: auto;
  border: none;
}

#intro {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}

main {
  float: left;
  width: 60%;
  padding-top: 20px;
}

aside {
  float: right;
  width: 30%;
  padding-top: 30px;
}

article {
  border-bottom: 2px dotted #999;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

article h2 {
  font-weight: normal;
  margin-bottom: 12px;
}

footer {
  background: #333;
  max-width: 1000px;
  margin: auto;
  clear: both;
  text-align: right;
}

footer p {
  padding: 20px;
  color: #fff;
  text-align: center;
  font-family: "Georgia";
}

address {
  padding: 10px 20px 30px 10px;
}

aside > div > div {
  margin: 10px auto;
  min-height: 73px;
  padding-left: 30px;
  padding-top: 15px;
}

.sidebar:hover {
  background: #f2f3f4;
  background: url("../assets/reishi.jpg");
}

.sidebar h1 {
  color: #523a28;
}

.sidebar h1:hover {
  color: #000;
}

.navigation h1:hover {
  background: #f2f3f4;
}

.navigation h1 {
  color: #523a28;
}

.navigation h1:hover {
  color: #000;
}

.typewriter p {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

#logo {
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  margin-right: 75px;
  color: #fff;
}

#logo img {
  width: 100px;
  vertical-align: middle;
  margin-right: 10px;
}

#feature {
  width: 324px;
  height: 324px;
  vertical-align: middle;
  margin-right: 10px;
}

/* Devil's Urn Effect */

.night-vision-effect {
  background-image: url("../assets/devilsurn.jpg"),
    radial-gradient(#00ff00, #000000),
    repeating-linear-gradient(
      transparent 0,
      rgba(0, 0, 0, 0.1) 2.5px,
      transparent 5px
    );
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  animation-duration: 0.1s;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: forward;
  animation-name: animate;
  width: 324px;
  height: 324px;
  vertical-align: middle;
  margin-right: 100px;
}

[class$="-effect"] img {
  vertical-align: top !important;
  margin: 0 !important;
  opacity: 0 !important;
}

/* CodePen Styles */
body {
  display: flex;
  height: 100vh;
}

/* div {
  margin: auto;
} */

@keyframes animate {
  0% {
    background-image: url(../assets/devilsurn.jpg),
      radial-gradient(#00ff00, #000000),
      repeating-linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 1.5px,
        transparent 5px
      );
  }

  50% {
    background-image: url(../assets/devilsurn.jpg),
      radial-gradient(#00ff00, #000000),
      repeating-linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 4.5px,
        transparent 5px
      );
  }
  100% {
    background-image: url(../assets/devilsurn.jpg),
      radial-gradient(#00ff00, #000000),
      repeating-linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 2.5px,
        transparent 5px
      );
  }
}

/* Devil's Urn Effect */

body > section {
  max-width: 1000px;
  margin: auto;
  border-bottom: 1px solid #999;
  color: #333;
}

#lichenbottom {
  margin-bottom: 2em;
}

input[type="submit"] {
  background: #333 top left repeat;
  width: 125px;
  height: 30px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  body > section {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 600px) {
  main {
    float: none;
    width: 100%;
  }

  aside {
    float: none;
    width: 100%;
  }
}
